import { Popover, Dialog,Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { FC,useState,Fragment,useEffect } from "react";
import { useLocation,useHistory } from 'react-router-dom';
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import AvatarDropdown from "./AvatarDropdown";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { NAVIGATION_DEMO } from "data/navigation";
import axios from 'axios'; 
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Cookies from 'js-cookie';
import Switch from "react-switch";



export interface MainNav1Props {
  isTop: boolean;
  homepage:string;
  autoFocus?: boolean;
  DefaultUserID?: string;
  targetlanguage?:string;
  referralCandy?:JSON;
}

const MainNav1: FC<MainNav1Props> = ({
  isTop ,
  homepage,
  autoFocus = false,
  DefaultUserID   = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
  targetlanguage  = localStorage.getItem("targetlanguage") ? localStorage.getItem("targetlanguage") : (window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw')?"zh_tw":"en",
  referralCandy   = localStorage.getItem("referralCandy") ? localStorage.getItem("referralCandy") :"",
}) => {
  const location = useLocation();
  var class_ab   =  '';
  var homepagecls = '';
  
  if(location.pathname=='/'){
      class_ab   =  'absolute';
      homepagecls = 'nav-home1';
  }
  const history                                             = useHistory();
  const [showPopover, setShowPopover]                       = useState(autoFocus);
  const [user_id,setUserId]                                 = useState(DefaultUserID);
  const [userProfilePic,setUserProfilePic]                  = useState("");
  const [totalCartValue,setTotalCartValue]                  = useState(0);
  const [defaultnegivation,setdefaultnegivation]            = useState(NAVIGATION_DEMO);
  const [isSaleModelOpen, setIsSaleModelOpen]               = useState(false);
  var is_thanks_giving_pop                                  = localStorage.getItem("is_thanks_giving_pop");
  const isPromotionModelOpen                                = Cookies.get('isPromotionModelOpen');
  const [checked, setChecked]                               = useState(targetlanguage=='en'?false:true);
  const [isOpenModalAmenities, setIsOpenModalAmenities]     = useState(false);
  let search                                                = window.location.search;
  let params                                                = new URLSearchParams(search);
  let aic                                                   = params.get('aic');
  let utm_source                                            = params.get('utm_source');
  if(utm_source!=null && utm_source!=''){
    const obj = {
        referralCanyCode: aic,
        utm_source: utm_source
    };
    localStorage.setItem('referralCandy', JSON.stringify({ ...obj }));
  }
  
//console.log(localStorage.getItem("referralCandy"));
  
// TIL 27 AUG it was over 
  // if(is_thanks_giving_pop!='yes'){
  //   if(window.location.hostname=='tinyaway.com' || window.location.hostname=='www.tinyaway.com'){
  //     localStorage.setItem("is_thanks_giving_pop","yes");
  //     setIsSaleModelOpen(true);
  //   }
  // }

  //START ON  07 NOV 2023 

  const SetCookie = () => {
    Cookies.set("isPromotionModelOpen", "yes", {
      expires: 1
    });
  };
  


  if(isPromotionModelOpen!='yes'){
   if(window.location.hostname=='tinyaway.com' || window.location.hostname=='www.tinyaway.com' || window.location.hostname=='localhost'){
     localStorage.setItem("is_thanks_giving_pop","yes");
     setIsSaleModelOpen(true);
   }
   SetCookie();
 }
  
  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+'getHeaderMenus_v1?language='+targetlanguage)
  .then(res => {
    console.log(res.data);
    setdefaultnegivation(res.data);
  });
},[]);


  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'/get_user_info?user_id='+DefaultUserID)
 	 .then(res => {
          if(res.data.status==true){
            setUserProfilePic(res.data.user_details.user_pic);
            setTotalCartValue(res.data.user_details.totalcart);
          }
    });   
  }, [DefaultUserID]);

  const showProfiles = () => {
      setShowPopover(true);
  }
  const logout = () => {
    localStorage.removeItem('user_id');
    history.push("/");
    history.go(0);
  };
  useEffect(() => {
    setUserId(localStorage.getItem("user_id"));
  }, [DefaultUserID]);
  const eventClickOutsideDiv = (event: MouseEvent) => {
    setShowPopover(false);
  };
  useEffect(() => {
    setUserId(DefaultUserID);
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);
  const renderRecentSearches = () => {
    return (
      <>
         <div className="">
            {DefaultUserID !="" ? 
            <ul>
            {/* <li><a href="#">Messages</a></li> */}

            <li><a href="/account">{targetlanguage=='zh_tw'?"帳戶":"Account"}</a></li>
            <li><a href="/wishlists">{targetlanguage=='zh_tw'?"願望清單":"Wishlists"}</a></li>
            <li><div className="line"></div></li>
            <li><a href="/host-a-tiny-house">{targetlanguage=='zh_tw'?"成為主持人":"Become a host"}</a></li>
            <li><a href="">{targetlanguage=='zh_tw'?"幫助":"Help"}</a></li>
            <li><a href="#" onClickCapture={logout}>{targetlanguage=='zh_tw'?"登出":"Log out"}</a></li>
            </ul>
            :
            <ul>
            <li><a href="/login">{targetlanguage=='zh_tw'?"登入":"Login"}</a></li>
            <li><a href="/signup">{targetlanguage=='zh_tw'?"登記":"Register"}</a></li>
            <li><div className="line"></div></li>
            <li><a href="/host-a-tiny-house">{targetlanguage=='zh_tw'?"成為主持人":"Become a host"}</a></li>
            <li><a href="">{targetlanguage=='zh_tw'?"幫助":"Help"}</a></li>
            </ul>
            }
         </div>
      </>
    );
  };

  function closeSaleModel() {
    setIsSaleModelOpen(false);
  }

  const renderSaleMotal = () => {
    return (
      <Transition appear show={isSaleModelOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto modell model-sm-min box-model-offers" onClose={closeSaleModel}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full">
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full height-none">
                  <div className="relative flex-shrink-0 px-0 py-0 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    > 
                    </h3>
                    <span className="absolute right-2 top-2 color-w--icon">
                      <ButtonClose className="bg-gray-100" onClick={closeSaleModel} />
                    </span>
                  </div>
                  <div className="px-0 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                     <div className="model-body">
                     <a href="/book-a-tiny-house"><img className="p-4" src="https://cdn.tinyaway.com/common/TA_PlayingCards_Website.jpg"/> </a>
                     {/* <a href="/book-a-tiny-house"><img className="p-4" src="https://cdn.tinyaway.com/common/eco_friendly_getaways.jpeg"/> </a> */}
                     {/* <a href="/book-a-tiny-house"><img className="p-4" src="https://cdn.tinyaway.com/common/tinyaway_vday_promo.jpg"/> </a>  */}
                      {/* <a href="/book-a-tiny-house"><img className="p-4" src="https://cdn.tinyaway.com/common/2023/08/sale_off_eighty_pe_latest1692259269711.jpeg"/> </a> */}
                       {/* <a href="/book-a-tiny-house"><img className="p-4" src="https://cdn.tinyaway.com/common/tiny_away_envoirment_banner.jpg"/> </a>  */}
                      {/*<a href="/book-a-tiny-house"><img className="p-4" src="https://cdn.tinyaway.com/common/thanks_giving_sale.jpg"/> </a>*/}
                     </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const handleChange = (nextChecked:any) => {
    setChecked(nextChecked);
    if(checked){
      localStorage.setItem("targetlanguage","en");
    }else{
      localStorage.setItem("targetlanguage","zh_tw");
    }
    window.location.reload();
  }
  return (
    <div
      className={`nc-MainNav1 ${class_ab} ${homepagecls} w-full z-10 ${isTop ? "onTop " : "notOnTop backdrop-filter"}`}>
      <div className="container container-order py-4 relative flex justify-between items-center space-x-4 xl:space-x-2">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation menuData={defaultnegivation} />
          {window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw' || window.location.hostname=='localhost' ?
          <div className="flex gap-2 change-languege py-1">ENG <Switch onColor={'#f15e75'} onChange={handleChange} checked={checked} /> CN</div>
          :""}
        </div>
        {/* <AvatarDropdown /> */}
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            {/*<SwitchDarkMode />*/}
            {/*<SearchDropdown />*/}
            <div className="px-1" />
            {DefaultUserID !="" ? "":""}
          </div>
          <div className="user-login" onClick={() => showProfiles()}>
          <span className="las la-bars-svg" ></span>
          <span className="las la-user-circle-svg">
            {userProfilePic!='' ?
            <img src={userProfilePic}></img>:""}</span>
          </div>
        {DefaultUserID !="" ? 
        <div className="user-login cart-top-menu"> 
        <a href="/checkout">
        <i className="cartIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
          <g clip-path="url(#clip0_1_6)">
          <path d="M7.26 7.8C7.26 7.8 7.49 8.04 7.84 7.74L14.03 2.14L20.31 7.83C20.31 7.83 20.63 8.04 20.86 7.77L21.42 7.13C21.42 7.13 21.63 6.79 21.4 6.58L18.79 4.28V1.31C18.79 1.22 18.71 1.12 18.61 1.13H17.52C17.3 1.12 17.32 1.31 17.31 1.42V2.69L14.33 0.1C14.33 0.1 13.97 -0.2 13.65 0.22L6.68 6.63C6.68 6.63 6.51 6.88 6.62 7.05L7.26 7.79V7.8Z" fill="#4E4D4E"/>
          <path d="M13.93 2.98L9.37 7.41C9.37 7.41 9.18 7.58 9.18 8.01V11.43C9.18 11.43 9.35 12.22 10.01 12.21H12.49C12.49 12.21 12.72 12.22 12.69 12.04L13.16 8.52H14.7C14.7 8.52 15.09 8.44 15.09 8.79V11.98C15.09 11.98 15.1 12.29 15.39 12.21L17.92 12.23C17.92 12.23 18.58 12.23 18.79 11.35V7.86C18.79 7.86 18.88 7.63 18.58 7.35L14.32 3.16C14.32 3.16 14.1 2.84 13.93 2.98Z" fill="#4E4D4E"/>
          <path d="M5.72 16.49C5.72 16.49 5.8 16.46 5.84 16.44C5.98 16.57 6.16 16.65 6.34 16.65H21.51C21.83 16.65 22.14 16.42 22.22 16.11C23.15 12.38 24.09 8.65 25.02 4.92C25.25 4 23.83 3.6 23.6 4.53C22.71 8.08 21.82 11.63 20.93 15.18H6.98C6.96 15.13 6.93 15.08 6.9 15.04C6.45 13.01 6 10.98 5.55 8.95C5.06 6.76 4.68 4.51 4.08 2.35C3.57 0.51 1.9 0.19 0.36 1.08C-0.46 1.56 0.28 2.83 1.11 2.36C1.96 1.87 2.47 1.96 2.69 2.87C2.9 3.7 3.06 4.55 3.25 5.39C3.88 8.22 4.51 11.05 5.14 13.88L5.4 15.04C3.89 15.63 2.8 17.21 3.12 18.9C3.34 20.08 4.22 21.24 5.45 21.49C5.5 21.5 5.56 21.49 5.61 21.51C5.97 22.96 7.27 24.05 8.83 24.05C10.39 24.05 11.59 23.04 12 21.67C13.34 21.7 14.68 21.73 16.03 21.76C16.47 23.09 17.7 24.06 19.18 24.06C20.66 24.06 21.81 23.16 22.28 21.9C22.85 21.91 23.41 21.93 23.98 21.94C24.93 21.96 24.93 20.48 23.98 20.46C23.48 20.45 22.97 20.44 22.47 20.43C22.31 18.74 20.9 17.41 19.18 17.41C17.46 17.41 16.12 18.66 15.9 20.28C15.6 20.28 15.3 20.27 15 20.26C14.04 20.24 13.07 20.22 12.11 20.2C11.85 18.62 10.49 17.41 8.84 17.41C7.19 17.41 5.92 18.53 5.59 20.03C4.61 19.72 4.43 18.46 4.67 17.74C4.85 17.19 5.22 16.76 5.74 16.52L5.72 16.49ZM19.16 18.87C20.18 18.87 21.01 19.7 21.01 20.72C21.01 21.74 20.18 22.57 19.16 22.57C18.14 22.57 17.31 21.74 17.31 20.72C17.31 19.7 18.14 18.87 19.16 18.87ZM8.82 18.87C9.84 18.87 10.67 19.7 10.67 20.72C10.67 21.74 9.84 22.57 8.82 22.57C7.8 22.57 6.97 21.74 6.97 20.72C6.97 19.7 7.8 18.87 8.82 18.87Z" fill="#4E4D4E"/>
          </g>
          <defs>
          <clipPath id="clip0_1_6">
          <rect width="25.05" height="24.04" fill="white"/>
          </clipPath>
          </defs>
        </svg>
        </i>
        </a>
        <span><b className="bg-icon-cart">{totalCartValue}</b></span>
        </div>
        :""}
          <div className="flex items-center xl:hidden">
            {/*<SwitchDarkMode />*/}
            <div className="px-1" />
            <MenuBar menuData={defaultnegivation} />
          </div>
        </div>
      </div>
      {showPopover && (
        <div className="absolute  z-40 drop-box bg-white dark:bg-neutral-800 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {renderRecentSearches()}
        </div>
      )}
      {renderSaleMotal()}
    </div>
  );
};
export default MainNav1;
