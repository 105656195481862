import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
const { REACT_APP_SITE_KEY } = process.env;
export interface PagePartnersProps {
  className?: string;
  autoFocus?: boolean;
}
const PagePartners: FC<PagePartnersProps> = ({
  className = "",
  autoFocus = false
}) => {

  const [details, setDetails] = useState({
    name: "",
    email: "",
    contactnumber: "",
    subject: "Keep in Touch with Tiny Away",
    message: "",
    google_captcha: ""
  });
  const [showPopover, setShowPopover]                       = useState(autoFocus);
  const [description, setDescription]                         = useState("");
  const [metaTitle, setMetaTitle]                             = useState("");
  const [bannnerUrl, setBannnerUrl]                           = useState("");
  const [isVideo, setIsVideo]                                 = useState("");
  const [isImages, setIsImages]                               = useState("no");
  const [isdataLoaded, setIsdataLoaded]                       = useState(false);
  const NameRef                                             = useRef<HTMLInputElement>(null);
  const EmailRef                                            = useRef<HTMLInputElement>(null);
  const ContactNumberRef                                    = useRef<HTMLInputElement>(null);
  const SubjectRef                                          = useRef<HTMLInputElement>(null);
  const MessagesRef                                         = useRef<HTMLInputElement>(null);
  const captchaRef                                          = useRef<ReCAPTCHA>(null);
  const [errorMsg, setErrorMsg]                             = useState('');
  const [successMsg, setSuccessMsg]                         = useState('');


  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+'getPages?page=about_us')
  .then(res => {
    console.log(res.data);
    setDescription(res.data.page_details.content_html);
    setMetaTitle(res.data.page_details.meta_title);
    setBannnerUrl(res.data.page_details.banner_url);
    setIsVideo(res.data.page_details.is_video);
    setIsImages(res.data.page_details.is_images);
    setIsdataLoaded(true);
  })
}, [isdataLoaded]);
const renderskeletonLoader = () => {
  return (
    <div className="custom-loader"><div className="h-screen skeleton"></div></div>
  );
};


const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
  const isInvalid = validateInput();
  if (!isInvalid) {
    //const token = captchaRef.current?.getValue();
      captchaRef.current?.reset();
     // console.log(token);
      //console.log(details.google_captcha);
      
    axios.post(process.env.REACT_APP_BASE_URL + '/customer_enquiries', details)
      .then(function (response) {
        //console.log(response.data.response);
        if (response.data.status == 200) {
          setSuccessMsg(response.data.response);
          setDetails({
            name: "",
            email: "",
            contactnumber: "",
            subject: "",
            message: "",
            google_captcha: ""
          });
        } else {
          setErrorMsg(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    setSuccessMsg('');
  }
};
useEffect(() => {
  //console.log({ showPopover, 'EmailRef': EmailRef.current, 'NameRef': NameRef.current });
  if (showPopover && NameRef.current && !details.name) {
    NameRef.current.focus();
  } else if (showPopover && EmailRef.current && !details.email) {
    EmailRef.current.focus();
  } else if (showPopover && ContactNumberRef.current && !details.contactnumber) {
    ContactNumberRef.current.focus();
  } else if (showPopover && SubjectRef.current && !details.subject) {
    SubjectRef.current.focus();
  } else if (showPopover && MessagesRef.current && !details.message) {
    MessagesRef.current.focus();
  }
}, [showPopover]);
const validateInput = () => {
  const fields = [
    {
      name: 'name',
      value: details.name,
      message: 'Your Name should not be blank.'
    },
    {
      name: 'email',
      value: details.email,
      message: 'Your Email should not be blank.'
    },
    {
      name: 'contactnumber',
      value: details.contactnumber,
      message: 'Contact Number should not be blank.'
    },
    {
      name: 'subject',
      value: details.subject,
      message: 'Subject should not be blank.'
    },
    {
      name: 'message',
      value: details.message,
      message: 'Message should not be blank.'
    },
    {
      id: 'google_captcha',
      value: details.google_captcha,
      message: 'Captcha should not be blank.'
    }
  ]
  const isNotFilled = fields.some(field => {
    if (field.value.trim() === '') {
      setErrorMsg(field.message);
      setShowPopover(true);
      return true;
    }
    setShowPopover(false);
    setErrorMsg('');
    return false;
  });
  return isNotFilled;
};
 function SetRecaptchaVal(value: any) {
   setDetails({ ...details, google_captcha:value});
 }

  return (
    <div className={`nc-PagePartners overflow-hidden relative ${className}`} data-nc-id="PagePartners">
      <Helmet title={metaTitle}></Helmet>
      <BgGlassmorphism />
      {isdataLoaded  ?
      <div className=" w-full">
      <div className="our-tiny p-0 pl-0">
        asdasd
      </div>
      </div>
      :
      <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
        {renderskeletonLoader()}
      </div>
      }
    </div>
    
  );
};

export default PagePartners;
